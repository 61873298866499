<template>
  <div
    @click="scrollDownHandler"
    :class="`scroll_down ${showElClass}`"
    :style="{ top: topPosition, left: leftPosition }"
  >
    <svg
      width="40"
      height="40"
      viewBox="0 0 142 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_902_5-899871)">
        <circle cx="71" cy="67" r="67" fill="#EDF2F5" />
      </g>
      <path
        d="M34.9316 78.3721C32.3561 75.7741 32.3561 71.5618 34.9316 68.9638C37.5072 66.3658 41.6829 66.3658 44.2584 68.9638L71 95.939L97.7416 68.9638C100.317 66.3658 104.493 66.3658 107.068 68.9638C109.644 71.5618 109.644 75.7741 107.068 78.3721L75.6634 110.051C73.0879 112.65 68.9121 112.65 66.3366 110.051L34.9316 78.3721ZM34.9316 40.3568C32.3561 37.7588 32.3561 33.5466 34.9316 30.9485C37.5072 28.3505 41.6829 28.3505 44.2584 30.9485L71 57.9238L97.7416 30.9485C100.317 28.3505 104.493 28.3505 107.068 30.9485C109.644 33.5466 109.644 37.7588 107.068 40.3568L75.6634 72.0362C73.0879 74.6342 68.9121 74.6342 66.3366 72.0362L34.9316 40.3568Z"
        fill="#8D99AF"
      />
      <defs>
        <filter
          id="filter0_d_902_5-899871"
          x="0"
          y="0"
          width="142"
          height="142"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_902_5"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_902_5"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    scrollTargetElClass: {
      type: String,
    },
    topPosition: {
      type: String,
      default: "80%",
    },
    leftPosition: {
      type: String,
      default: "50%",
    },
    visibleTopPosition: {
      type: Number,
      default: 150,
    },
    visibleBottomPosition: {
      type: Number,
      default: 150,
    },
  },

  data() {
    return {
      showElClass: "",
      bodyEl: "",
      bodyElHeight: 0,
    };
  },

  mounted() {
    this.getBodyElement();
    this.initializeScrollElement();
  },

  beforeUnmount() {
    this.removeScrollElement();
  },

  methods: {
    getBodyElement() {
      const bodyEl =
        document.querySelector(this.scrollTargetElClass) || document.documentElement;
      this.bodyEl = bodyEl;
    },

    getEventTarget() {
      return this.bodyEl === document.documentElement ? window : this.bodyEl;
    },

    initializeScrollElement() {
      this.bodyElHeight = this.bodyEl.scrollHeight;
      const eventTarget = this.getEventTarget();
      eventTarget.addEventListener("scroll", this.updateScrollPosition);
    },

    removeScrollElement() {
      const eventTarget = this.getEventTarget();
      eventTarget.removeEventListener("scroll", this.updateScrollPosition);
    },

    scrollDownHandler() {
      this.bodyEl.scrollTo({
        top: this.bodyElHeight,
        behavior: "smooth",
      });
    },

    updateScrollPosition() {
      const { scrollTop, clientHeight } = this.bodyEl;
      const isAtBottom =
        Math.ceil(scrollTop + clientHeight + this.visibleBottomPosition) >= this.bodyElHeight;
      this.showElClass =
        scrollTop > this.visibleTopPosition && !isAtBottom ? "show_scroll_btn" : "";
    },
  },
};
</script>

<style scoped>
.scroll_down {
  visibility: hidden;
  position: fixed;
  transform: translateX(-50%);
  z-index: 10;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}

.show_scroll_btn {
  visibility: visible;
  opacity: 0.75;
  transition: opacity 0.3s ease, visibility 0s linear 0s;
}
</style>
