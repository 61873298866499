<template>
  <div class="register_container">
    <TopNav />

    <div class="min-vh-100 d-flex justify-content-center my-5">
      <div class="form-access my-auto glass_a">
        <form @submit.prevent="handleSubmit">
          <span>Buat Akun</span>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Nama"
              v-model="name"
              @click="isError = false"
            />
          </div>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Alamat Email"
              v-model="email"
              @click="isError = false"
            />
          </div>
          <div class="form-group position-relative">
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              ref="password"
              v-model="password"
              @click="isError = false"
            />
            <TogglePassword @is-password-show="togglePasswordValue" />
          </div>
          <div class="form-group position-relative">
            <input
              type="password"
              class="form-control"
              placeholder="Ulangi Password"
              ref="rePassword"
              v-model="password_confirm"
              @click="isError = false"
            />
            <TogglePassword @is-password-show="toggleRePasswordValue" />
          </div>
          <div class="custom-control custom-checkbox mb-4">
            <input
              type="checkbox"
              class="checkmark"
              id="form-checkbox"
              v-model="checkbox"
              @click="handleShowModal"
            />
            <label class="" for="form-checkbox">
              Saya setuju dengan
              <a
                href="/pages/term-and-conditions"
                class="primary-link"
                target="_blank"
                >Syarat dan Ketentuan.</a
              >
            </label>
          </div>
          <div class="form-group">
            <VueRecaptcha
              ref="recaptcha"
              @verify="onVerify"
              :sitekey="recapSiteKey"
            ></VueRecaptcha>
          </div>

          <button type="submit" class="btn btn_gradient">
            <span v-if="!loading" class="m-0" style="font-size: 1rem"
              >Buat Akun</span
            >
            <span
              v-else
              class="spinner-border spinner-border-sm mx-auto my-0"
              aria-hidden="true"
            ></span>
          </button>
        </form>

        <!-- errr response -->
        <ErrorAlert :show="isError" :message="errorMessage" />

        <h2>
          Sudah Mempunyai Akun?
          <router-link to="/auth/login" class="primary-link"
            >Masuk disini</router-link
          >
        </h2>
      </div>
    </div>

    <Footer />

    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <TermCondition :show="showModal" @close="showModal = false">
        <template #header>
          <h3 class="m-auto text-center text-light">
            Syarat, Ketentuan dan Tata Tertib Perdagangan
          </h3>
        </template>
        <template #footer>
          <div class="btn_gr m-auto m-md-0">
            <button v-if="isDisabled" class="isDisabled">Menerima</button>
            <button v-else @click="handleAcept" class="btn_gradient">
              Menerima
            </button>
            <button @click="handleCloseModal" class="btn_secondary">
              Menolak
            </button>
          </div>
        </template>
        <template #scrollDown>
          <BtnScrollDown :scrollTargetElClass="'.modal-body'" />
        </template>
      </TermCondition>

      <ModalSuccess :show="showModalRes" @close="showModalRes = false">
        <template #body>
          <div class="btn_close">
            <svg
              @click="showModalRes = false"
              data-v-2cdd5bbc=""
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                data-v-2cdd5bbc=""
                cx="10.5"
                cy="10.5"
                r="10.5"
                fill="#F6465D"
              ></circle>
              <path
                data-v-2cdd5bbc=""
                d="M16.275 14.122C16.275 14.3694 16.1884 14.5798 16.0152 14.753L14.753 16.0152C14.5798 16.1884 14.3694 16.275 14.122 16.275C13.8745 16.275 13.6641 16.1884 13.4909 16.0152L10.7625 13.2867L8.03409 16.0152C7.86086 16.1884 7.6505 16.275 7.40303 16.275C7.15556 16.275 6.9452 16.1884 6.77197 16.0152L5.50985 14.753C5.33662 14.5798 5.25 14.3694 5.25 14.122C5.25 13.8745 5.33662 13.6641 5.50985 13.4909L8.23826 10.7625L5.50985 8.03409C5.33662 7.86086 5.25 7.6505 5.25 7.40303C5.25 7.15556 5.33662 6.9452 5.50985 6.77197L6.77197 5.50985C6.9452 5.33662 7.15556 5.25 7.40303 5.25C7.6505 5.25 7.86086 5.33662 8.03409 5.50985L10.7625 8.23826L13.4909 5.50985C13.6641 5.33662 13.8745 5.25 14.122 5.25C14.3694 5.25 14.5798 5.33662 14.753 5.50985L16.0152 6.77197C16.1884 6.9452 16.275 7.15556 16.275 7.40303C16.275 7.6505 16.1884 7.86086 16.0152 8.03409L13.2867 10.7625L16.0152 13.4909C16.1884 13.6641 16.275 13.8745 16.275 14.122Z"
                fill="#FAFAFA"
              ></path>
            </svg>
          </div>
          <div v-if="isSuccess == true">
            <SuccessAnimation />
          </div>

          <div v-else>
            <ErrorAnimation />
          </div>
          <p style="text-align: center">
            {{ resMsg }}
          </p>
        </template>
      </ModalSuccess>
    </Teleport>
  </div>
</template>

<script>
// node_modules
import { VueRecaptcha } from "vue-recaptcha";
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import TermCondition from "@/components/modals/TermConditionModal.vue";
import ModalSuccess from "@/components/modals/ModalSuccess.vue";
import SuccessAnimation from "@/components/animations/SuccessAnimation.vue";
import ErrorAnimation from "@/components/animations/ErrorAnimation.vue";
import TogglePassword from "@/components/global/TogglePassword.vue";
import ErrorAlert from "@/components/global/ErrorAlert.vue";
import BtnScrollDown from "@/components/global/BtnScrollDown.vue";

export default {
  components: {
    TopNav,
    Footer,
    TermCondition,
    VueRecaptcha,
    ModalSuccess,
    SuccessAnimation,
    ErrorAnimation,
    TogglePassword,
    ErrorAlert,
    BtnScrollDown
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      name: "",
      email: "",
      password: "",
      password_confirm: "",
      checkbox: false,
      recapSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaToken: "",
      showModal: false,
      showModalRes: false,
      isSuccess: false,
      isDisabled: true,
      isError: false,
      errorMessage: null,
      resMsg: "",
      loading: false,
    };
  },

  async mounted() {
    var self = this;

    if (self.token) {
      self.$router.push("/");
    }

    self.handleScroll();
  },

  methods: {
    async submitForm() {
      var self = this;
      this.loading = true;

      if (self.recaptchaToken != "") {
        if (self.password == self.password_confirm) {
          var res = await thestore.postRegister(
            self.name,
            self.email,
            self.password,
            self.password_confirm,
            self.recaptchaToken
          );
          if (res.success == true) {
            self.isSuccess = true;
            self.showModalRes = true;
            self.resMsg = res.msg;
            self.name = "";
            self.email = "";
            self.password = "";
            self.password_confirm = "";

            setTimeout(() => {
              self.$router.push("/auth/login");
            }, 10000);
          } else {
            self.isSuccess = false;
            self.showModalRes = true;
            self.resMsg = res.msg;
            if (res.msg == "Wrong captcha") {
              setTimeout(() => {
                self.$router.go();
              }, 2000);
            }
          }
        } else {
          alert("Password confirmation is not the same");
        }
      }

      this.loading = false;
    },

    onVerify(response) {
      var self = this;
      if (response) {
        // User input is valid
        self.recaptchaToken = response;
      } else {
        // User input is invalid
        self.recaptchaToken = "";
      }
    },

    handleScroll() {
      var self = this;
      const termsEl = document.querySelector(".modal-body");

      if (termsEl) {
        termsEl.addEventListener("scroll", (e) => {
          if (termsEl.scrollTop + window.innerHeight >= termsEl.scrollHeight) {
            self.isDisabled = false;
            // console.log("ok");
          }
        });
      }
    },

    // method ini untuk menghandle modal
    // untuk mendetaksi scrool
    // baca class ".modal-body"
    // karna class tsb baru muncul saat ada modal maka jalankan handleScroll saat modal true
    // kasih delay biar bener2 dapat classnya
    handleSubmit() {
      var self = this;

      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const formatPassword = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

      self.isError = false;

      if (self.name === "") {
        self.isError = true;
        self.errorMessage = "Nama tidak boleh kosong";
      }

      if (self.email === "") {
        self.isError = true;
        self.errorMessage = "Email tidak boleh kosong";
      } else if (!emailFormat.test(self.email)) {
        self.isError = true;
        self.errorMessage = "Email tidak valid";
      }

      if (self.password === "") {
        self.isError = true;
        self.errorMessage = "Password tidak boleh kosong";
      } else if (self.password.length < 8) {
        self.isError = true;
        self.errorMessage = "Password min 8 karakter";
      } else if (!formatPassword.test(self.password)) {
        self.isError = true;
        self.errorMessage =
          " Password is weak. Password must minimum 8 char, 1 uppercase, and 1 number";
      }

      if (self.password_confirm === "") {
        self.isError = true;
        self.errorMessage = "Confirm password tidak boleh kosong";
      } else if (self.password_confirm !== self.password) {
        self.isError = true;
        self.errorMessage = "Password tidak sama";
      }

      if (self.recaptchaToken === "") {
        self.isError = true;
        self.errorMessage = "Pastikan Anda bukan robot";
      }

      if (!self.checkbox) {
        self.isError = true;
        self.errorMessage = "Syarat dan ketentuan wajib disetujui";
      }

      if (!self.isError) {
        self.submitForm();
      }
    },

    handleShowModal() {
      var self = this;

      self.showModal = true;
      setTimeout(() => {
        self.handleScroll();
      }, 200);
    },

    handleAcept() {
      var self = this;

      self.isError = false;
      self.showModal = false;
    },

    handleCloseModal() {
      var self = this;

      self.isError = true;
      self.errorMessage = "Syarat dan ketentuan wajib disetujui";
      self.checkbox = false;
      self.showModal = false;
      self.isDisabled = true;
    },

    togglePasswordValue(val) {
      const password = this.$refs.password;
      password.type = val ? "text" : "password";
    },

    toggleRePasswordValue(val) {
      const rePassword = this.$refs.rePassword;
      rePassword.type = val ? "text" : "password";
    },
  },
};
</script>

<style scoped>
.register_container {
  position: relative;
  height: 100%;
  background-image: url("/images/bg-02.png");
  background-repeat: no-repeat;
  background-position: bottom;
}

.btn_gr button {
  width: 130px;
  height: 35px;
  border: none;
  border-radius: 5px;
}

.btn_secondary {
  margin-left: 15px;
}

.isDisabled {
  background: grey;
  cursor: not-allowed;
  color: #fff;
}

.scroll_dwn {
  position: fixed;
  bottom: 20%;
  cursor: pointer;
}

.header_m {
  font-weight: bold;
  font-size: 20px;
  color: #cc2204;
}

.form-control {
  color: black !important;
}

.btn_gradient {
  background-color: #708cc7 !important;
  color: #fff;
}

.btn_gradient:hover {
  background-color: #5271b4 !important;
}

.primary-link {
  color: #708cc7;
}

.custom-checkbox {
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  align-items: center;
}

.custom-checkbox label {
  margin: 0;
  padding: 0;
}

.checkmark {
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.glass_a {
  /* From https://css.glass */
  background: linear-gradient(
    114deg,
    rgba(121, 67, 239, 0.18) 0%,
    rgba(108, 36, 161, 0.29) 36%,
    rgba(33, 93, 154, 0.28) 72%,
    rgba(146, 105, 197, 0.43) 100%
  );

  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 30px 0;
}

.btn_close {
  position: absolute;
  right: -7%;
  top: -7%;
  cursor: pointer;
}

.min-vh-100 {
  min-height: 100vh;
}

@media (max-width: 500px) {
  .header_m {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) {
  .glass_a {
    padding: 30px;
  }
}
</style>
